import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Link } from "gatsby"
import ContactUsForm from "../components/common/ContactUsForm";

class ContactUs extends Component {
    render() {
        return (
            <Layout>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2">
                            <h1>Contact Us</h1>
                            <p>
                                Let us know how we can help you and we'll get back with you as soon as possible.
                            </p>
                            <ContactUsForm forwardTo={'/thank-you'}/>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

ContactUs.propTypes = {}

export default ContactUs
